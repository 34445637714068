import { Header, Mozaik, Subheader, Section, Title } from '../../components';
import { GridBlock, GridContentBlock } from '../../components/GridContentBlock';
import { ASSETS_PATH } from '../../utils';
import styles from './About.module.scss';

const mozaikPictures = ['pu-demo.jpeg', 'run.jpeg', 'toinou.jpg', 'rope.jpeg', 'wallball.jpeg'];
const xp: GridBlock[] = [
  {
    title: `MON EXPÉRIENCE`,
    type: 'list',
    texts: [
      `COACH Particulier depuis 2011`,
      `Professeur dans une salle de sport Parisienne`,
      `Professeur de judo en club privé`,
      `Animations d’Ateliers bleus sportif en école primaire`
    ]
  },
  {
    title: `MON PARCOURS`,
    type: 'list',
    texts: [
      `Master 2, spécialisé Sport, Santé et Nutrition`,
      `Licence STAPS, spécialisé Entraînement sportif`,
      `Préparation physique multisport`,
      `Formation Pilates`
    ]
  }
];

const faqPart1: GridBlock[] = [
  {
    title: `POURQUOI DU COACHING INDIVIDUEL ?`,
    texts: [
      `Après plus de 2 ans en tant que coach dans un magnifique club de sport parisien (club
  Jean de Beauvais) où j’ai développé et apprécié le suivi personnalisé des clients, je
  ressentais l’envie de me lancer dans l’aventure en solo.`,
      `Depuis novembre 2011, c’est en coaching personnel sportif et nutritionnel que je
  transmets mon goût pour le sport et l’entretien physique !`,
      `Ce statut d’indépendant laisse libre cours à mon imagination pour les séances et me
  permet de compléter l’entraînement sportif de mes clients par un suivi diététique.`
    ]
  },
  {
    title: `POURQUOI DES SESSIONS COLLECTIVES ?`,
    texts: [
      `Les entraînements collectifs sont :`,
      `– pour particulier, groupe de 4 à 8 personnes ayant un objectif commun.`,
      ` – pour les Comités d’Entreprise, groupe jusqu’à 18 personnes, séances à thèmes
    (fessiers abdos cuisses, fitness, stretching, …)`,
      `– en extérieur, au bois de Vincennes. Séances à inscription obligatoire, accessibles à
    tous, essentiellement basées sur une méthode d’entraînement en circuit training
    (circuit training)`,
      `Cela se destine à un public qui préfère s’entraîner en groupe.`,
      ` Il y a une façon tout à fait différente de s’entraîner.. on retrouve un esprit de
    groupe, une forme de concurrence qui tire vers le haut, une motivation à se retrouver
    entre amis et les tarifs y sont plus abordables.`
    ]
  }
];

const faqPart2: GridBlock[] = [
  {
    title: `QU'EST CE QU'UNE SÉANCE TYPE ?`,
    texts: [
      `Tout d’abord, on organise une séance d’essai. Elle permet de définir les objectifs,
  évaluer le niveau de forme physique, dresser un bilan et informer sur mes méthodes
  d’entrainements.`,
      `Tel que je l’envisage, il n’y a pas de séance type car j’ai à coeur de proposer des
  exercices variés et ludiques, mais surtout personnalisés.`,
      `Il faut compter environ 1h. Entre l’échauffement, le travail spécifique cardio ou de
  renforcement musculaire et l’indispensable retour au calme accompagné d’étirements.`
    ]
  },
  {
    title: `VOUS COACHEZ PLUS D'HOMMES OU DE FEMMES ?`,
    texts: [
      `Actuellement, j’ai davantage de femmes que d’hommes dans mes coachings individuels.
  Mais ce sont les profils et les objectifs qui importent, plutôt que le sexe de la
  personne.`
    ]
  },
  {
    title: `PLUTÔT EN INTÉRIEUR OU EN EXTÉRIEUR ?`,
    texts: [
      `Cela va dépendre de plusieurs critères : Les préférences de mon client, l’envie du
      moment, les objectifs bien sûr, la météo, la pollution et le niveau de forme.`
    ]
  }
];

export const About = () => {
  return (
    <>
      <Header
        background="apropos.jpg"
        title="À propos"
        subtitle="EN SAVOIR PLUS SUR LE COACH ET SES ACTUS"
        size="small"
      />
      <Subheader
        picture="coursparticuliers2.jpg"
        position="right"
        title="MON COACH, MA LIGNE, MA FORME"
        text="Le sport selon moi n'est pas uniquement synonyme de performance...
C'est avant tout une notion de plaisir atteint par le simple fait de pratiquer une activité que l'on aime, ou par la satisfaction de se motiver pour en faire."
      />
      <Section>
        <Title isCentered size={3} part1="VOTRE" part2="COACH" part2Color="green" />
        <div className={styles.aboutBlock}>
          <img
            className={styles.picture}
            alt="À propos de SimonCoach"
            src={ASSETS_PATH + 'simon2.jpeg'}
          />
          <div className={styles.aboutText}>
            <h4>QUI SUIS-JE ?</h4>
            <h5>SIMON DELAU</h5>
            <p>
              {
                "Dès mes 3 ans, je commence à pratiquer le judo. Mon avenir sportif s'y dessine durant plus de 20 ans entre entraînements, compétitions et débuts en tant qu’entraîneur."
              }
            </p>
            <p>
              Mon mentor : Michel Sanchìs, ancien judoka de haut niveau, m’a beaucoup appris avec sa
              pédagogie, sa rigueur et ses techniques qu’il applique avec brio.
            </p>
            <p>Comme une évidence, je me suis dirigé vers la filière STAPS.</p>
            <p>
              Même si je m’intéresse à tous les sports, c’est le volley-ball qui est devenu mon
              défouloir en dehors des coachings depuis plus de 10 ans.
            </p>
          </div>
        </div>

        <GridContentBlock blocks={xp} nbCols={2} />
      </Section>
      <Section>
        <Title
          className={styles.faqTitlte}
          isCentered
          size={3}
          part1="LES"
          part2="QUESTIONS FRÉQUENTES"
          part2Color="green"
        />

        <GridContentBlock blocks={faqPart1} nbCols={2} />

        <GridContentBlock blocks={faqPart2} nbCols={3} />
      </Section>

      <Mozaik pictures={mozaikPictures} />
    </>
  );
};
