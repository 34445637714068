import { CSSProperties } from 'react';
import styles from './GridContentBlock.module.scss';
import classnames from 'classnames';

export type GridBlock = {
  title: React.ReactNode;
  texts: React.ReactNode[];
  type?: 'text' | 'list';
};

type GridContentBlockProps = {
  className?: string;
  itemClassName?: string;
  blocks: GridBlock[];
  nbCols?: 1 | 2 | 3 | 4;
  nbColsTablet?: 1 | 2 | 3 | 4;
  nbColsMobile?: 1 | 2 | 3 | 4;
};

export const GridContentBlock = ({
  nbCols = 4,
  nbColsTablet = 2,
  nbColsMobile = 1,
  blocks,
  className,
  itemClassName
}: GridContentBlockProps) => {
  return (
    <div
      className={classnames(styles.gridContent, className)}
      style={
        {
          '--nb-cols': nbCols,
          '--nb-cols-tablet': nbColsTablet,
          '--nb-cols-mobile': nbColsMobile
        } as CSSProperties
      }>
      {blocks.map((block, index) => (
        <div
          key={`content-block-${index}`}
          className={classnames(
            styles.block,
            { [styles.centered]: block.type === 'list' },
            itemClassName
          )}>
          <h4>{block.title}</h4>
          {block.type === 'list' ? (
            <ul>
              {block.texts.map((text, textIndex) => (
                <li key={`content-item-${index}-${textIndex}`}>{text}</li>
              ))}
            </ul>
          ) : (
            block.texts.map((text, textIndex) => (
              <p key={`content-item-${index}-${textIndex}`}>{text}</p>
            ))
          )}
        </div>
      ))}
    </div>
  );
};
