import { useCallback, useMemo } from 'react';
import styles from './IconBlocks.module.scss';
import classnames from 'classnames';

export type IconBlock = {
  icon?: string;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  value?: string;
  alt?: string;
  type: 'mailto' | 'callto' | 'link' | 'cta' | 'none';
  blank?: boolean;
};

type BlocksProps = {
  items: IconBlock[];
  className?: string;
  itemClassName?: string;
  nbCols?: 1 | 2 | 3 | 4;
  nbColsTablet?: 1 | 2 | 3 | 4;
  nbColsMobile?: 1 | 2 | 3 | 4;
  marginSize?: 'S' | 'M';
  darkMode?: boolean;
  iconPosition?: 'top' | 'left';
  withSeparator?: boolean;
  onClick?: (index: number) => void;
};

export const IconBlocks = ({
  items,
  nbCols = 4,
  nbColsTablet = 2,
  nbColsMobile = 1,
  className,
  itemClassName,
  darkMode = false,
  marginSize = 'M',
  iconPosition = 'top',
  withSeparator = true,
  onClick
}: BlocksProps) => {
  const { colSize, colSizeTablet, colSizeMobile } = useMemo(() => {
    return {
      colSize: `col-md-${12 / nbCols}`,
      colSizeTablet: `col-sm-${12 / nbColsTablet}`,
      colSizeMobile: `col-xs-${12 / nbColsMobile}`
    };
  }, [nbCols, nbColsTablet, nbColsMobile]);

  const onCtaClicked = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => {
      e.stopPropagation();
      e.preventDefault();
      if (onClick) {
        onClick(index);
      }
    },
    [onClick]
  );

  return (
    <div
      className={classnames('row', styles.blocks, className, styles[`margin${marginSize}`], {
        [styles.darkMode]: darkMode
      })}>
      {items.map((item, i) => {
        const link = item.type === 'link' ? item.value : `${item.type}:${item.value}`;
        return (
          <a
            key={`linkTo${item.value || item.title}`}
            href={item.type !== 'cta' ? link : undefined}
            title={item.alt}
            className={classnames(
              colSize,
              colSizeTablet,
              colSizeMobile,
              styles.block,
              {
                [styles.withIcon]: !!item.icon,
                [styles.highlight]: item.type !== 'none',
                [styles.leftIcon]: iconPosition === 'left',
                [styles.withSeparator]: withSeparator
              },
              itemClassName
            )}
            target={item.blank === true ? '_blank' : '_self'}
            onClick={item.type === 'cta' && onClick ? (e) => onCtaClicked(e, i) : undefined}
            rel="noreferrer">
            {item.icon && <span className={classnames('fa', styles.icon, item.icon)}></span>}
            <span className={styles.text}>
              {item.title}
              <br />
              <small className={styles.small}>{item.subtitle ? item.subtitle : <>&nbsp;</>}</small>
            </span>
          </a>
        );
      })}
    </div>
  );
};
