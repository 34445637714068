import { Header, Section, Subheader, Title } from '../components';
import { BlockSeparator } from '../components/BlockSeparator';
import { GridBlock, GridContentBlock } from '../components/GridContentBlock';
import { Mozaik } from '../components/Mozaik';

const trainingInfos: GridBlock[] = [
  {
    title: `QU'EST CE QUE LE CROSSTRAINING ? `,
    texts: [
      `Le principe du cross training est d'allier le fitness, l'endurance, le mental et ne jamais connaître à l'avance le type de séance qui nous attend. On ressent vite les progrès et on peut en devenir addict à cette forme d'entraînement !`
    ]
  },
  {
    title: `OÙ SE DÉROULENT LES SÉANCES ?`,
    texts: [
      `Sous la forme d'un bootcamp en outdoor toute l'année pour plus de défi, le groupe se retrouve sur la piste d'athlétisme au stade Léo-Lagrange, situé à Porte de charenton (Paris 12)`,
      <a
        key={'stade'}
        href="https://www.google.fr/maps/place/Stade+L%C3%A9o-Lagrange/@48.832162,2.399346,17z/data=!3m1!4b1!4m2!3m1!1s0x47e6725d0a185885:0xc18e7931ff4df970"
        target="_blank"
        rel="noreferrer">
        <span className="fa fa-map-marker"></span>&nbsp;&nbsp;<b>{`Comment s'y rendre ?`}</b>
      </a>
    ]
  },
  {
    title: `LES RENDEZ-VOUS`,
    type: 'list',
    texts: [`MERCREDI - 19H00`, `SAMEDI - 11H20`]
  }
];

const tarifs: GridBlock[] = [
  {
    title: `À L'UNITÉ`,
    type: 'list',
    texts: [
      <Title
        key="unit-price"
        size={4}
        part1=""
        part2="8 €"
        part1Color="green"
        part2Color="green"
        isCentered
      />
      //   <a key="buy-one" href="./tarifs">
      //     <b>ACHETER</b>
      //   </a>
    ]
  },
  {
    title: `CARNET DE 10 SÉANCES`,
    type: 'list',
    texts: [
      <Title
        key="pack-10-price"
        size={4}
        part1=""
        part2="70 €"
        part1Color="green"
        part2Color="green"
        isCentered
      />
      //   `soit 7 € / cours`
      //   <a key="buy-many" href="./tarifs">
      //     <b>ACHETER</b>
      //   </a>
    ]
  }
  //   {
  //     title: `ABONNEMENT ANNUEL`,
  //     type: 'list',
  //     texts: [
  //       `50 € / MOIS`,
  //       <a key="subscribe" href="./tarifs">
  //         <b>SOUSCRIRE</b>
  //       </a>
  //     ]
  //   }
];

const mozaikPictures = [
  'battle-rope.jpg',
  'training-run.jpg',
  'jump.jpg',
  'battle-rope-2.jpg',
  'training-selfie.jpg'
];

const redirectionBlocks = [
  {
    titlePart1: 'DÉCOUVRIR LES',
    titlePart2: 'COURS PARTICULIERS',
    link: './cours-particulier',
    background: 'cours_collectifs.jpg'
  },
  {
    titlePart1: 'EN SAVOIR PLUS SUR',
    titlePart2: 'LE SUIVI DIÉTÉTIQUE',
    link: './suivi-dietetique',
    background: 'votre_coach.jpg'
  }
];

export const Training = () => {
  return (
    <>
      <Header
        background="700.jpg"
        title="COURS COLLECTIFS À PARIS 12"
        subtitle="Rejoingnez le groupe de CROSSTRAINING"
        size="small"
      />

      <Subheader
        picture="simon-portrait.png"
        position="left"
        title="DESTINÉ À CEUX QUI PRÉFÈRENT S'ENTRAINER EN GROUPE"
        text="Il y a une façon tout à fait différente de s'entraîner.. on retrouve un esprit de groupe, une forme de concurrence qui tire vers le haut, une motivation à se retrouver entre amis et les tarifs y sont plus abordables."
      />

      <Section>
        <Title size={3} part1="Les" part2="SÉANCES" part2Color="green" isCentered />
        <GridContentBlock blocks={trainingInfos} nbCols={3} />
        <br />
        <br />
        <br />
        <Title size={3} part1="Les" part2="tarifs" part2Color="green" isCentered />

        <GridContentBlock blocks={tarifs} nbCols={2} />

        <BlockSeparator blocks={redirectionBlocks} />
      </Section>

      <Mozaik pictures={mozaikPictures} />
    </>
  );
};
