import { Header, Section, Title } from '../components';
import { IconBlocks, IconBlock } from '../components/IconBlocks';
import { Mozaik } from '../components/Mozaik';

const homeServices: IconBlock[] = [
  {
    icon: 'fa-home',
    title: (
      <>
        COACHING À DOMICILE
        <br />
        SUR PARIS
      </>
    ),
    type: 'link',
    value: './cours-particulier'
  },
  {
    icon: 'fa-line-chart',
    title: (
      <>
        SÉANCES COMPLÈTES <br /> EN PHYSIQUE ET <br /> EN VISIO
      </>
    ),
    type: 'link',
    value: './cours-particulier'
  },
  {
    icon: 'fa-pagelines',
    title: (
      <>
        SUIVI DIÉTÉTIQUE
        <br />
        PERSONNALISÉ
      </>
    ),
    type: 'link',
    value: './suivi-dietetique'
  },
  {
    icon: 'fa-users',
    title: (
      <>
        COURS COLLECTIFS
        <br />
        DE CROSS-TRAINING
      </>
    ),
    type: 'link',
    value: './cours-collectifs',
    blank: true
  }
];

const mozaikPictures = ['simon.jpg', '700.jpg', 'plank.jpeg'];

const moreInfosBlocks: IconBlock[] = [
  {
    icon: 'fa-signal',
    title: '6 PROGRAMMES',
    subtitle: 'ADAPTÉS À TOUS VOS BESOINS',
    type: 'link',
    value: './cours-particulier'
  },
  {
    icon: 'fa-tag',
    title: 'LES TARIFS',
    subtitle: 'DÉCOUVRIR LES FORMULES',
    type: 'link',
    value: './cours-particulier'
  },
  {
    icon: 'fa-newspaper-o',
    title: 'VOTRE COACH',
    subtitle: 'EN SAVOIR PLUS',
    type: 'link',
    value: './about'
  }
];

export const Home = () => {
  return (
    <>
      <Header
        background="run-training-high.jpg"
        title="Coach Sportif & Diététique"
        subtitle="COACHING À DOMICILE / SUIVI DIÉTÉTIQUE / COURS COLLECTIFS"
        size="small"
      />
      <Section>
        <Title size={3} part1="Mes" part2="Services" part2Color="green" isCentered />
        <IconBlocks items={homeServices} />

        <br />
        <br />
        <br />

        <Title size={3} part1="Plus" part2="d'infos" part2Color="green" isCentered />
        <IconBlocks nbCols={3} nbColsTablet={3} nbColsMobile={1} items={moreInfosBlocks} />
      </Section>

      <Mozaik pictures={mozaikPictures} />
    </>
  );
};
