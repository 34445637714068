import classnames from 'classnames';
import { Title } from '../Title';
import styles from './Footer.module.scss';
import { Section } from '../Section';
import { Footer as FooterModel } from '../../models/types';
import { IconBlocks } from '../IconBlocks';
import { Link } from 'react-router-dom';

const footerModel: FooterModel = {
  blocks: [
    {
      icon: 'fa-list-alt',
      title: 'PAR FOMULAIRE',
      subtitle: 'contact@simoncoach.fr',
      type: 'link',
      value:
        'https://docs.google.com/forms/d/e/1FAIpQLSeTBeFqvZktYVlAbB-mH57NMDRm292BP80u8Sv83XE03NqIpg/viewform?usp=sf_link',
      blank: true
    },
    {
      icon: 'fa-envelope-o',
      title: 'PAR EMAIL',
      subtitle: 'contact@simoncoach.fr',
      type: 'mailto',
      value: 'contact@simoncoach.fr'
    },
    {
      icon: 'fa-phone',
      title: 'PAR TÉLÉPHONE',
      subtitle: '06 43 45 51 49',
      type: 'callto',
      value: '0643455149'
    },
    {
      icon: 'fa-instagram',
      title: 'SUR INSTAGRAM',
      type: 'link',
      value: 'https://instagram.com/simon__coach/',
      blank: true
    }
  ]
};

export const Footer = () => {
  return (
    <>
      <Section background="grey">
        <Title
          size={3}
          part1={<>ME</>}
          part2={<>CONTACTER</>}
          part1Color="white"
          part2Color="green"
        />
        <IconBlocks items={footerModel.blocks} darkMode />
      </Section>

      <Section className={styles.footer} background="darkgrey" margin="S">
        <div className={classnames(styles.left, 'col-md-10 col-xs-12')}></div>

        <div className="col-md-2 col-xs-12">
          <a href="mailto:contact@simoncoach.fr" title="Contactez moi par mail">
            contact@simoncoach.fr
          </a>
        </div>
      </Section>
    </>
  );
};
