import { Link } from 'react-router-dom';
import styles from './Logo.module.scss';

export const Logo = () => {
  return (
    <Link to="/">
      <div className={styles.logo}>
        <span className={styles.part1}>SIMON</span>
        <span className={styles.part2}>COACH</span>
      </div>
    </Link>
  );
};
