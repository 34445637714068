import classnames from 'classnames';
import styles from './Subheader.module.scss';
import { CSSProperties } from 'react';
import { ASSETS_PATH } from '../../utils';

type SubheaderProps = {
  title: React.ReactNode;
  text: React.ReactNode;
  picture: string;
  position: 'left' | 'right';
};

export const Subheader = ({ title, text, picture, position }: SubheaderProps) => {
  return (
    <div className={classnames(styles.subheader, styles[position])}>
      <div className={styles.wrapper}>
        <div
          className={styles.picture}
          style={{ '--background': `url(${ASSETS_PATH + picture})` } as CSSProperties}
        />
        <div className={styles.textCtn}>
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};
