import classnames from 'classnames';
import styles from './Title.module.scss';

type TitleColors = 'grey' | 'green' | 'white';

type TitleProps = {
  size: 1 | 2 | 3 | 4;
  part1: React.ReactNode;
  part1Color?: TitleColors;
  part2: React.ReactNode;
  part2Color?: TitleColors;
  isCentered?: boolean;
  className?: string;
};

export const Title = ({
  size,
  part1,
  part1Color,
  part2,
  part2Color,
  isCentered = false,
  className
}: TitleProps) => {
  return (
    <h3
      className={classnames(styles[`size${size}`], className, {
        [styles.centered]: isCentered
      })}>
      {part1 && (
        <span className={classnames(styles.part1, styles[part1Color || 'grey'])}>{part1}</span>
      )}
      {part2 && (
        <span className={classnames(styles.part2, styles[part2Color || 'grey'])}>{part2}</span>
      )}
    </h3>
  );
};
