import classnames from 'classnames';
import styles from './Modal.module.scss';

type ModalProps = {
  open: boolean;
  children: React.ReactNode;
  className?: string;
  onClose: () => void;
};

export const Modal = ({ open, children, className, onClose }: ModalProps) => {
  return (
    <>
      <div
        className={classnames(styles.overlay, { [styles.isOpen]: open })}
        onClick={onClose}></div>
      <div className={classnames(styles.modal, { [styles.isOpen]: open }, className)}>
        <span onClick={onClose} className={classnames('fa fa-close', styles.closeButton)}></span>
        {children}
      </div>
    </>
  );
};
