import classnames from 'classnames';
import styles from './BlockSeparator.module.scss';
import { CSSProperties } from 'react';
import { ASSETS_PATH } from '../../utils';

type BlockSeparatorProps = {
  blocks: { titlePart1?: string; titlePart2?: string; background?: string; link?: string }[];
};

export const BlockSeparator = ({ blocks }: BlockSeparatorProps) => {
  return (
    <div className={styles.blockSeparator}>
      {blocks.map((block) => (
        <a
          href={block.link}
          key={block.link}
          className={styles.block}
          style={
            (block.background
              ? { '--background': `url(${ASSETS_PATH + block.background})` }
              : {}) as CSSProperties
          }>
          <span className={classnames(styles.texts, styles.text1)}>{block.titlePart1}</span>
          <span className={classnames(styles.texts, styles.text2)}>{block.titlePart2}</span>
        </a>
      ))}
    </div>
  );
};
