import { Outlet } from 'react-router-dom';

import { Logo, Menu, Footer } from './components';

export const Layout = () => {
  return (
    <>
      <Logo />
      <Menu />
      <Outlet />
      <Footer />
    </>
  );
};
