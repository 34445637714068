import { Route, Routes, useLocation } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

import { Home } from './routes/Home';
import { About } from './routes/About';
import { Layout } from './Layout';

import './App.scss';
import { Training } from './routes/Training';
import { PersonnalTraining } from './routes/PersonnalTraining';
import { Diet } from './routes/Diet';

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <Routes location={location}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="cours-particulier" element={<PersonnalTraining />} />
          <Route path="cours-collectifs" element={<Training />} />
          <Route path="suivi-dietetique" element={<Diet />} />
          <Route path="about" element={<About />} />
        </Route>
      </Routes>
      <Analytics />
    </div>
  );
}

export default App;
