import classnames from 'classnames';
import styles from './Text.module.scss';

type TextProps = {
  children: React.ReactNode;
  className?: string;
};

export const Text = ({ children, className }: TextProps) => {
  return <p className={classnames(styles.text, className)}>{children}</p>;
};
