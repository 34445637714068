import { CSSProperties } from 'react';
import styles from './Header.module.scss';
import classnames from 'classnames';
import { ASSETS_PATH } from '../../utils';

type HeaderProps = {
  size?: 'small' | 'tall';
  background: string;
  title: React.ReactNode;
  subtitle: React.ReactNode;
};

export const Header = ({ size = 'tall', background, title, subtitle }: HeaderProps) => {
  return (
    <div
      className={classnames(styles.header, styles[size])}
      style={{ '--background-image': `url(${ASSETS_PATH + background})` } as CSSProperties}>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </div>
  );
};
