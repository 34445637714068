import { Header, Section, Subheader, Title, Text, IconBlocks, IconBlock } from '../components';
import { BlockSeparator } from '../components/BlockSeparator';
import { GridBlock, GridContentBlock } from '../components/GridContentBlock';
import { Mozaik } from '../components/Mozaik';

import styles from './Diet.module.scss';

const details: IconBlock[] = [
  {
    icon: 'fa-heartbeat',
    title: 'BILAN NUTRITIONNEL',
    type: 'none',
    subtitle: (
      <div className={styles.subtitle}>
        {`Questionnaire sur les habitudes alimentaires. Mise en lumière d'éléments potentiellement perturbant l'équilibre alimentaire et provoquant la prise de poids. Élaboration de menus appropriés.`}
      </div>
    )
  },
  {
    icon: 'fa-flash',
    title: 'Équilibre alimentaire',
    type: 'none',
    subtitle: (
      <div className={styles.subtitle}>
        {`Comprendre ce qu'est un repas équilibré, une journée équilibrée, un mode de vie équilibré. L'hygiène de vie alimentaire pour améliorer vos résultats ! Sous forme de conseils nutritionnels adaptés à chacun.`}
      </div>
    )
  },
  {
    icon: 'fa-pagelines',
    title: 'Suivi à la semaine',
    type: 'none',
    subtitle: (
      <div className={styles.subtitle}>
        {`Mise en place d'un suivi détaillé de votre nutrition repas par repas. Un suivi journalier afin d'avoir un regard constructif sur votre alimentation. Remplissage d'un tableau pour garder le contrôle le plus précis possible !`}
      </div>
    )
  },
  {
    icon: 'fa-pagelines',
    title: 'Nutrition du sportif',
    type: 'none',
    subtitle: (
      <div className={styles.subtitle}>
        {`Afin de bien préparer une épreuve sportive ou professionnelle, mais aussi pour garder un maximum d'energie pour vos séances d'entraînement sans gâcher vos efforts ! Atteingnez plus vite vos objectifs avec une nutrition adaptée à votre pratique.`}
      </div>
    )
  }
];

const tarifs: GridBlock[] = [
  {
    title: 'Bilan nutritionnel',
    type: 'list',
    texts: [
      <>
        <Title
          key="unit-price"
          size={4}
          part1=""
          part2="90 €"
          part1Color="green"
          part2Color="green"
          isCentered
          className={styles.price}
        />
        <Title
          key="unit-price"
          size={4}
          part1=""
          part2="1h30"
          part1Color="grey"
          part2Color="grey"
          isCentered
        />
      </>
    ]
  },
  {
    title: `Suivi à la semaine`,
    type: 'list',
    texts: [
      <>
        <Title
          key="unit-price"
          size={4}
          part1=""
          part2="45 €"
          part1Color="green"
          part2Color="green"
          isCentered
          className={styles.price}
        />
        <Title
          key="unit-price"
          size={4}
          part1=""
          part2="45min"
          part1Color="grey"
          part2Color="grey"
          isCentered
        />
      </>
    ]
  },
  {
    title: `Pack de 5 séances`,
    type: 'list',
    texts: [
      <>
        <Title
          key="unit-price"
          size={4}
          part1=""
          part2="275 €"
          part1Color="green"
          part2Color="green"
          isCentered
          className={styles.price}
        />
        <Title
          key="unit-price"
          size={4}
          part1=""
          part2="5h00"
          part1Color="grey"
          part2Color="grey"
          isCentered
        />
      </>
    ]
  }
];

const tarifs2: GridBlock[] = [
  {
    title: `Les bases de la nutrition`,
    type: 'list',
    texts: [
      <>
        <Title
          key="unit-price"
          size={4}
          part1=""
          part2="20 € / pers"
          part1Color="green"
          part2Color="green"
          isCentered
          className={styles.price}
        />
        <Title
          key="unit-price"
          size={4}
          part1=""
          part2="env. 1h30 - minimum 5 pers"
          part1Color="grey"
          part2Color="grey"
          isCentered
        />
      </>
    ]
  }
];

const mozaikPictures = ['wallball.jpeg', 'run.jpeg', 'plank-coaching.jpeg'];

const redirectionBlocks = [
  {
    titlePart1: 'DÉCOUVRIR LES',
    titlePart2: 'COURS PARTICULIERS',
    link: './cours-particulier',
    background: 'cours_collectifs.jpg'
  },
  {
    titlePart1: 'DÉCOUVRIR LES',
    titlePart2: 'COURS COLLECTIFS',
    link: './cours-collectifs',
    background: 'votre_coach.jpg'
  }
];

export const Diet = () => {
  return (
    <>
      <Header
        background="coursparticuliers.jpg"
        title="SUIVI DIÉTÉTIQUE"
        subtitle="Votre accompagnement passe également par le coaching nutritionnel"
        size="small"
      />

      <Subheader
        picture="coaching-dark-vador.jpeg"
        position="right"
        title="UNE APPROCHE COMPLÈTE POUR ATTEINDRE VOS OBJECTIFS BIEN-ÊTRE"
        text="En association avec les séances de coaching particulier, le suivi nutritionnel sur mesure maximisera vos résultats. Un équilibre parfait entre l'exercice physique et une alimentation adaptée pour vous aider à atteindre vos objectifs plus rapidement et de manière durable. Séance réalisable sur RDV  à domicile, en entreprise ou en visio ?"
      />

      <Section>
        <Title size={3} part1="Mon" part2="ACCOMPAGNEMENT" part2Color="green" isCentered />
        <Text className={styles.text}>
          {
            "Fort de mes 15 ans d'expériences en coaching particulier et d'un Master 2 Nutrition Sport et Santé, bénéficiez de conseils et de plans alimentaires adaptés. Avec des séances de suivi diététique et donnez une nouvelle dimension à votre parcours vers une vie plus saine et équilibrée."
          }
        </Text>
        <IconBlocks
          nbCols={2}
          nbColsTablet={2}
          items={details}
          itemClassName={styles.programms}
          iconPosition="left"
          withSeparator={false}
        />
        <br />
        <br />
        <br />
        <Title size={3} part1="Les" part2="tarifs" part2Color="green" isCentered />
        <GridContentBlock blocks={tarifs} nbCols={3} />
        <br />
        <br />
        <br />
        <Title size={3} part1="Cours de" part2="DIÉTÉTIQUE" part2Color="green" isCentered />
        <Text className={styles.text}>
          {`Organisez vos cours de diététique en petit groupe ! Conçus pour vous et vos amis (minimum 6 personnes). Explorez ensemble les fondamentaux de la nutrition dans une atmosphère conviviale. Ces sessions interactives vous guideront à travers les bases essentielles, vous fournissant les connaissances nécessaires pour prendre des décisions éclairées sur votre alimentation. C'est l'occasion idéale de partager cette expérience enrichissante avec d'autres tout en travaillant collectivement vers une meilleure compréhension de la nutrition.`}
        </Text>
        <GridContentBlock blocks={tarifs2} nbCols={1} />

        <BlockSeparator blocks={redirectionBlocks} />
      </Section>

      <Mozaik pictures={mozaikPictures} />
    </>
  );
};
