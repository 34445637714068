import { useCallback, useState } from 'react';
import styles from './Menu.module.scss';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

const links = [
  {
    label: 'Accueil',
    path: '/'
  },
  {
    label: 'Cours Particulier',
    path: '/cours-particulier'
  },
  {
    label: 'Cours Collectifs',
    path: '/cours-collectifs'
  },
  {
    label: 'Suivi Diététique',
    path: '/suivi-dietetique'
  },
  {
    label: 'À propos',
    path: '/about'
  }
];

export const Menu = () => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <>
      <button type="button" onClick={toggleMenu} className={styles.btMenu} />
      <nav className={classnames(styles.nav, { [styles.open]: isOpen })} role="navigation">
        <div onClick={toggleMenu} className={styles.closeMenu}></div>
        <div className={styles.ctn}>
          <h1 className={styles.menuTitle}>MENU</h1>
          <ul className={styles.menuList}>
            {links.map((link) => (
              <li
                key={'link' + link.path}
                className={classnames({ [styles.selected]: location.pathname === link.path })}>
                <Link to={link.path} onClick={toggleMenu}>
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
          <div className={styles.btContacts}>
            <a href="mailto:contact@simoncoach.fr">
              <span className={'fa fa-envelope-o'}></span>
            </a>
            <a href="callto:0643455149">
              <span className={'fa fa-phone'}></span>
            </a>
            <a
              href="https://www.facebook.com/pages/Simon-Coach/147966911971971?fref=ts"
              target="_blank"
              rel="noreferrer">
              <span className={'fa fa-facebook-square'}></span>
            </a>
            <a href="https://instagram.com/simon__coach/" target="_blank" rel="noreferrer">
              <span className={'fa fa-instagram'}></span>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};
