import { CSSProperties } from 'react';
import styles from './Mozaik.module.scss';
import classnames from 'classnames';
import { ASSETS_PATH } from '../../utils';

type MozaikProps = {
  pictures: string[];
};

/**
 * This component aim to work with only for 3 or 5 pictures
 * first one will be display in full height
 * other half the height
 */
export const Mozaik = ({ pictures }: MozaikProps) => {
  if (pictures.length !== 3 && pictures.length !== 5) return null;

  return (
    <div className={classnames(styles.mozaik, { [styles.threeCols]: pictures.length === 5 })}>
      {pictures.map((picture) => (
        <div
          key={`picture${picture}`}
          className={styles.picture}
          style={{ '--background': `url(${ASSETS_PATH + picture})` } as CSSProperties}
        />
      ))}
    </div>
  );
};
