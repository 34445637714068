import classnames from 'classnames';
import styles from './Section.module.scss';
import React from 'react';

type SectionProps = {
  className?: string;
  background?: 'white' | 'grey' | 'darkgrey';
  margin?: 'S' | 'M';
  children?: React.ReactNode;
};

export const Section = ({
  className,
  background = 'white',
  margin = 'M',
  children
}: SectionProps) => {
  return (
    <section className={classnames(className, styles.section, styles[background])}>
      <div className={classnames(styles.container, styles[`size${margin}`])}>{children}</div>
    </section>
  );
};
