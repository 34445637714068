import { Header, IconBlock, IconBlocks, Modal, Section, Subheader, Title } from '../components';
import { BlockSeparator } from '../components/BlockSeparator';
import { GridBlock, GridContentBlock } from '../components/GridContentBlock';
import { Mozaik, Text } from '../components';
import styles from './PersonnalTraining.module.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';
import { Group, Table, Button } from 'evergreen-ui';

const programmes1: IconBlock[] = [
  {
    icon: 'fa-heartbeat',
    title: 'CLASSIQUE',
    type: 'cta',
    subtitle: (
      <div className={styles.subtitle}>
        <span>Remise en forme / entretien</span>
        <span className={styles.blockClickable}>1 séance / semaine</span>
        <span className={styles.click}>Cliquez pour en savoir +</span>
      </div>
    )
  },
  {
    icon: 'fa-flash',
    title: 'INTENSIF',
    type: 'cta',
    subtitle: (
      <div className={styles.subtitle}>
        <span>Objectif précis / sportif</span>
        <span className={styles.blockClickable}>2 séances / semaine ou +</span>
        <span className={styles.click}>Cliquez pour en savoir +</span>
      </div>
    )
  },
  {
    icon: 'fa-pagelines',
    title: 'MINCEUR',
    type: 'cta',
    subtitle: (
      <div className={styles.subtitle}>
        <span>Objectif ciblé / ligne</span>
        <span className={styles.blockClickable}>1 à 2 séances / semaine</span>
        <span className={styles.click}>Cliquez pour en savoir +</span>
      </div>
    )
  }
];
const programmes2: IconBlock[] = [
  {
    title: 'FEMMES ENCEINTES',
    type: 'cta',
    subtitle: (
      <div className={styles.subtitle}>
        <span>pré / post accouchement</span>
        <span className={styles.blockClickable}>2 séances / semaine</span>
        <span className={styles.click}>Cliquez pour en savoir +</span>
      </div>
    )
  },
  {
    title: 'SÉNIORS',
    type: 'cta',
    subtitle: (
      <div className={styles.subtitle}>
        <span>Maintient de la forme</span>
        <span className={styles.blockClickable}>1 à 2 séances / semaine</span>
        <span className={styles.click}>Cliquez pour en savoir +</span>
      </div>
    )
  },
  {
    title: 'JUNIOR',
    type: 'cta',
    subtitle: (
      <div className={styles.subtitle}>
        <span>Préparation spécifique</span>
        <span className={styles.blockClickable}>2 séances / semaine</span>
        <span className={styles.click}>Cliquez pour en savoir +</span>
      </div>
    )
  }
];

const modulPart1: GridBlock[] = [
  {
    title: `À DOMICILE, OUTDOOR OU EN VISIO`,
    texts: [
      `Chez vous pour plus de confort, en plein air pour profiter de l'air frais, ou même en visio car vous ne résidez pas dans la région Parisienne.`,
      `Votre coach s'adapte à votre style de pratique sportive et vos disponibilités pour vos proposez des séances motivantes `
    ]
  },
  {
    title: (
      <>
        EN SOLO, EN DUO OU
        <br />
        EN PETITS GROUPES
      </>
    ),
    texts: [
      `Que vous préfériez vous entraîner seul pour une expérience plus personnalisée, ou que vous souhaitiez partager l'énergie et la motivation avec un groupe d'amis, les séances s'adaptent à vos besoins.`
    ]
  },
  {
    title: (
      <>
        {"A L'UNITÉ, PAR PACK"}
        <br />
        OU ABONNEMENT
      </>
    ),
    texts: [
      `Vous avez le choix entre trois options de paiement : à l'unité pour plus de flexibilité, en pack avec des séances à tarif réduit (valable 4 mois), ou en abonnement pour un suivi à long terme (d'une durée minimale de 6 mois).`
    ]
  }
];

const mozaikPictures = ['pu-demo.jpeg', 'run.jpeg', 'toinou.jpg', 'rope.jpeg', 'wallball.jpeg'];

const redirectionBlocks = [
  {
    titlePart1: 'DÉCOUVRIR LES',
    titlePart2: 'COURS COLLECTIFS',
    link: './cours-collectifs',
    background: 'cours_collectifs.jpg'
  },
  {
    titlePart1: 'EN SAVOIR PLUS SUR',
    titlePart2: 'LE SUIVI DIÉTÉTIQUE',
    link: './suivi-dietetique',
    background: 'votre_coach.jpg'
  }
];

type ModalContent = {
  titlePart1: React.ReactNode;
  titlePart2: React.ReactNode;
  text: React.ReactNode;
};

const modalContents: ModalContent[] = [
  {
    titlePart1: 'Programme',
    titlePart2: 'CLASSIQUE',
    text: `Entretenez votre forme physique et mentale avec un entraînement hebdomadaire à domicile. Rien ne remplace une séance de sport !`
  },
  {
    titlePart1: 'Programme',
    titlePart2: `INTENSIF`,
    text: `Atteignez vos objectifs sportif, minceur, diététique et/ou de remise en forme plus rapidement avec 2 entraînements par semaine. Vous sentirez vite la différence !    `
  },
  {
    titlePart1: 'Programme',
    titlePart2: `MINCEUR`,
    text: `Programmez 1 à 2 séance par semaine liant des exercices qui retravaillent votre ligne et un suivi diététique personnalisé. Les kilos en trop peuvent s’inquiéter !`
  },
  {
    titlePart1: 'Programme',
    titlePart2: `FEMMES ENCEINTES`,
    text: (
      <>
        Préparez, renforcez, relaxez et assouplissez vous jusqu’à 6 mois de grossesse grâce à de
        nombreux exercices sans risques et bénéfiques !<br /> <br />
        Après l’accouchement, reprenez en douceur avec des exercices adaptés afin de ne pas mélanger
        vitesse et précipitation !
      </>
    )
  },
  {
    titlePart1: 'Programme',
    titlePart2: `SÉNIORS`,
    text: `Gardez un bon niveau de forme général, de la souplesse et surtout l’envie de bouger avec des entraînements basés sur l’entretien physique musculaire, articulaire et la souplesse.`
  },
  {
    titlePart1: 'Programme',
    titlePart2: `JUNIORS`,
    text: `L’entraînement encadré est la solution idéale aussi bien pour préparer un sport spécifique, retravailler la condition physique ou gérer un trop plein d’énergie.`
  },
  {
    titlePart1: 'AGRÉÉ',
    titlePart2: `SERVICE À LA PERSONNE`,
    text: (
      <>
        <b>PROFITEZ DES RÉDUCTIONS D’IMPÔTS SUR VOS COURS DE SPORT A DOMICILE.</b>
        <br />
        <br />
        Le Service à la Personne est le meilleur moyen aujourd’hui d’obtenir une aide extérieure
        personnalisée dans des conditions fiscales avantageuses. Grâce à l’obtention de cet
        agrément, SIMON COACH vous fait profiter d’une
        <b> réduction de 50% sur le prix de vos cours de sport à domicile</b>.
        <br />
        <br />
        En effet, vous bénéficiez en tant que particulier et au titre du recours aux services d’une
        personne à domicile de :<br />
        50% de Réduction d’impôts sur les heures de cours de sport à domicile effectuées si vous
        êtes imposable.
        <br />
        50% de Crédit d’impôts sur les heures de cours de sport à domicile effectuées si vous êtes
        non imposable.
        <br />
        <br />
        Le bénéfice de cette déduction fiscale sera de droit dès lors que nous vous aurons établi
        une attestation fiscale mentionnant les sommes que vous avez versées au titre des cours de
        sport à domicile durant l’année précédente.
        <br />
        <br />
        SIMON COACH est fier de contribuer à faire en sorte que les cours de sport à domicile soient
        accessibles au plus grand nombre.
      </>
    )
  }
];

type SessionType = 'solo' | 'duo' | 'trio';

type PriceBase = {
  unit: number;
  pack10: number;
  subOneWeekly: number;
  subThreeWeekly: number;
};

const pricesBase: Record<SessionType, PriceBase> = {
  solo: { unit: 75, pack10: 70, subOneWeekly: 65, subThreeWeekly: 60 },
  duo: { unit: 50, pack10: 45, subOneWeekly: 40, subThreeWeekly: 35 },
  trio: { unit: 120, pack10: 950, subOneWeekly: 35, subThreeWeekly: 30 }
};

const REMOTE_PROMO = 20;
const SAP_PROMO = 50;

export const PersonnalTraining = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalContent = useRef<ModalContent | undefined>(undefined);
  const iconBlock1Clicked = useCallback((index: number) => {
    modalContent.current = modalContents[index];
    setIsModalOpen(true);
  }, []);
  const iconBlock2Clicked = useCallback((index: number) => {
    modalContent.current = modalContents[programmes1.length + index];
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  const onSAPClicked = useCallback(() => {
    modalContent.current = modalContents[6];
    setIsModalOpen(true);
  }, []);

  const modulPart2: GridBlock[] = useMemo(
    () => [
      {
        title: `ANIMATIONS POUR CE & ENTREPRISES`,
        texts: [
          `Retrouvez votre coach lors de votre pause déjeuner ou pour vous défouler en équipe après une bonne journée de travail !`,
          <>
            <b>Bénéficiez de tarifs avantageux</b> pour une séance d’une heure. C’est l’occasion de
            vous dépensez entre collègues !
          </>
        ]
      },
      {
        title: `AGRÉÉ SERVICE À LA PERSONNE`,
        texts: [
          <span className={styles.sap} key="logosap">
            <span className={styles.logo}>
              <img src={`${process.env.PUBLIC_URL}/logosap.png`} />
            </span>
            Profitez de 50% de réduction sur tous les tarifs sous forme de crédit d’impots grâce.
          </span>,
          <span
            key={'sap-learnmore'}
            className={classnames(styles.subtitle, styles.sapButton)}
            onClick={onSAPClicked}>
            <span className={styles.blockClickable}>En savoir +</span>
          </span>
        ]
      }
    ],
    [onSAPClicked]
  );

  const contactMe: IconBlock[] = useMemo(
    () => [
      {
        icon: 'fa-list-alt',
        title: 'VIA LE FORMULAIRE',
        subtitle: 'de contact simplifié',
        type: 'link',
        blank: true,
        value:
          'https://docs.google.com/forms/d/e/1FAIpQLSeTBeFqvZktYVlAbB-mH57NMDRm292BP80u8Sv83XE03NqIpg/viewform?usp=sf_link'
      },
      {
        icon: 'fa-envelope-o',
        title: 'VIA EMAIL',
        subtitle: 'contact@simoncoach.fr',
        type: 'mailto',
        value: 'contact@simoncoach.fr'
      },
      {
        icon: 'fa-phone',
        title: 'VIA TÉLÉPHONE',
        subtitle: '06 43 45 51 49',
        type: 'callto',
        value: '0643455149'
      }
    ],
    []
  );

  const typeOptions = useMemo(
    () => [
      { label: 'Solo', value: 'solo' },
      { label: 'Duo', value: 'duo' },
      { label: 'Petit groupe', value: 'trio' },
      { label: 'Entreprise', value: 'company' }
    ],
    []
  );

  const whereOptions = useMemo(
    () => [
      { label: 'À DOMICILE / OUTDOOR', value: 'presentiel' },
      { label: 'EN VISIO', value: 'visio' }
    ],
    []
  );

  const sapOptions = useMemo(
    () => [
      { label: 'AVEC', value: 'with' },
      { label: 'SANS', value: 'without' }
    ],
    []
  );
  const [selectedType, setSelectedType] = useState<SessionType | 'company'>('solo');
  const [selectedLocation, setSelectedLocation] = useState('presentiel');
  const [selectedSAP, setSelectedSAP] = useState('without');
  const [animated, setAnimated] = useState(false);

  const prices = useMemo(() => {
    if (selectedType === 'company') return undefined;
    const locationPromo = selectedLocation === 'visio' ? 1 - REMOTE_PROMO / 100 : 1;
    const sapPromo = selectedSAP === 'with' ? 1 - SAP_PROMO / 100 : 1;
    const base = pricesBase[selectedType];
    return {
      classic: {
        unit: Math.ceil(base.unit * locationPromo),
        pack10: Math.ceil(base.pack10 * locationPromo),
        subOneWeekly: Math.ceil(base.subOneWeekly * locationPromo),
        subThreeWeekly: Math.ceil(base.subThreeWeekly * locationPromo)
      },
      withPromo:
        sapPromo !== 1
          ? {
              unit: Math.ceil(base.unit * locationPromo * sapPromo),
              pack10: Math.ceil(base.pack10 * locationPromo * sapPromo),
              subOneWeekly: Math.ceil(base.subOneWeekly * locationPromo * sapPromo),
              subThreeWeekly: Math.ceil(base.subThreeWeekly * locationPromo * sapPromo)
            }
          : undefined
    };
  }, [selectedLocation, selectedSAP, selectedType]);

  useEffect(() => {
    setAnimated(true);
    setTimeout(() => {
      setAnimated(false);
    }, 350);
  }, [prices, selectedType]);

  const forCompany = useMemo(() => selectedType === 'company', [selectedType]);

  const withSap = useMemo(() => selectedSAP === 'with', [selectedSAP]);

  return (
    <>
      <Header
        background="coursparticuliers.jpg"
        title="Cours particulier"
        subtitle="Des programmes adaptés à tous vos besoins"
        size="small"
      />

      <Subheader
        picture="plank-coaching.jpeg"
        position="left"
        title="ÊTRE ACCOMPAGNÉ POUR ATTEINDRE SES OBJECTIFS"
        text="Découvrez les programmes de coaching particulier conçu pour vous aider à atteindre vos objectifs sportifs de manière efficace et personnalisée. Que vous préfériez vous entraîner seul pour une expérience plus personnalisée, ou que vous souhaitiez partager l'énergie et la motivation avec un groupe d'amis, les séances s'adaptent à vos besoins."
      />

      <Section>
        <Title size={3} part1="Les" part2="PROGRAMMES" part2Color="green" isCentered />

        <Text className={styles.text}>
          Votre coach se déplace avec le matériel nécessaire pour vous proposer une séance d’une
          heure, ludique et adaptée à vos besoins (état de forme, envie de maigrir, se rafermir,
          avoir plus de souffle, mal de dos, ...)
        </Text>

        <Title size={4} part1="LES PROGRAMMES" part2="STANDARDS" part2Color="grey" />

        <IconBlocks
          nbCols={3}
          nbColsTablet={3}
          items={programmes1}
          itemClassName={styles.programms}
          onClick={iconBlock1Clicked}
        />

        <Title size={4} part1="LES PROGRAMMES" part2="SPÉCIFIQUES" part2Color="grey" />
        <IconBlocks
          nbCols={3}
          nbColsTablet={3}
          items={programmes2}
          itemClassName={styles.programms}
          onClick={iconBlock2Clicked}
        />

        <Title
          className={styles.title2}
          size={3}
          part1="des séances"
          part2="MODULABLES"
          part2Color="green"
          isCentered
        />
        <GridContentBlock
          blocks={modulPart1}
          nbCols={3}
          nbColsTablet={1}
          nbColsMobile={1}
          itemClassName={styles.gridItemTitle}
        />
        <GridContentBlock
          blocks={modulPart2}
          nbCols={2}
          nbColsTablet={1}
          nbColsMobile={1}
          itemClassName={styles.gridItemTitle}
        />

        <Title
          className={styles.title2}
          size={3}
          part1="les"
          part2="TARIFS"
          part2Color="green"
          isCentered
        />
        <Table>
          <Table.Head className={styles.head}>
            <Table.TextHeaderCell className={styles.headCell}>
              COMPOSER VOTRE SÉANCE SUR MESURE
            </Table.TextHeaderCell>
          </Table.Head>
          <Table.Head className={styles.head}>
            <Table.TextHeaderCell className={styles.headCell}>
              <Group size="small" className={styles.group}>
                {typeOptions.map(({ label, value }) => (
                  <Button
                    key={label}
                    isActive={selectedType === value}
                    onClick={() => setSelectedType(value as SessionType)}>
                    {label}
                  </Button>
                ))}
              </Group>
            </Table.TextHeaderCell>

            <Table.TextHeaderCell
              className={classnames(styles.headCell, { [styles.disabled]: forCompany })}>
              LIEU &nbsp;
              <Group size="small" className={styles.group} aria-disabled="true">
                {whereOptions.map(({ label, value }) => (
                  <Button
                    key={label}
                    isActive={selectedLocation === value}
                    onClick={() => setSelectedLocation(value)}>
                    {label}
                  </Button>
                ))}
              </Group>
            </Table.TextHeaderCell>

            <Table.TextHeaderCell
              className={classnames(styles.headCell, { [styles.disabled]: forCompany })}>
              SERVICE À LA PERSONNE &nbsp;
              <Group size="small" className={styles.group}>
                {sapOptions.map(({ label, value }) => (
                  <Button
                    key={label}
                    isActive={selectedSAP === value}
                    onClick={() => setSelectedSAP(value)}>
                    {label}
                  </Button>
                ))}
              </Group>
            </Table.TextHeaderCell>
          </Table.Head>
        </Table>

        {prices && (
          <div
            className={classnames(styles.pricesTabWrapper, {
              [styles.withSap]: withSap,
              [styles.animated]: animated
            })}>
            <Table className={styles.twoColsTable}>
              <Table.Head className={styles.subscriptionHeader}>
                <Table.TextHeaderCell>Sans Abonnement</Table.TextHeaderCell>
              </Table.Head>
              <Table.Head className={styles.typeHeader}>
                <Table.TextHeaderCell>{"À l'unité"}</Table.TextHeaderCell>
                <Table.TextHeaderCell>Pack de 10 séances</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                <Table.Row className={styles.bodyTab}>
                  <Table.TextCell className={styles.bodyCell}>
                    <div className={styles.price}>
                      {prices.withPromo ? (
                        <>
                          <span className={styles.strokePrice}>{prices.classic.unit} €</span>
                          {prices.withPromo.unit} €
                        </>
                      ) : (
                        <>{prices.classic.unit} €</>
                      )}
                    </div>
                    <br />
                    <span className={styles.subprice}>
                      prix / pers
                      <br />
                      &nbsp;
                    </span>
                  </Table.TextCell>
                  <Table.TextCell className={classnames(styles.bodyCell, styles.secondCol)}>
                    <div className={styles.price}>
                      {prices.withPromo ? (
                        <>
                          <span className={styles.strokePrice}>{prices.classic.pack10} €</span>
                          {prices.withPromo.pack10} €
                        </>
                      ) : (
                        <>{prices.classic.pack10} €</>
                      )}
                    </div>
                    <br />
                    <span className={styles.subprice}>
                      prix / séance / pers <br />
                      (valable 4 mois)
                    </span>
                  </Table.TextCell>
                </Table.Row>
              </Table.Body>
            </Table>

            <Table className={styles.twoColsTable}>
              <Table.Head className={styles.subscriptionHeader}>
                <Table.TextHeaderCell>Avec Abonnement</Table.TextHeaderCell>
              </Table.Head>
              <Table.Head className={styles.typeHeader}>
                <Table.TextHeaderCell>1 séance / semaine</Table.TextHeaderCell>
                <Table.TextHeaderCell>3 séances / semaine</Table.TextHeaderCell>
              </Table.Head>

              <Table.Body>
                <Table.Row className={styles.bodyTab}>
                  <Table.TextCell className={styles.bodyCell}>
                    <div className={styles.price}>
                      {prices.withPromo ? (
                        <>
                          <span className={styles.strokePrice}>
                            {prices.classic.subOneWeekly} €
                          </span>
                          {prices.withPromo.subOneWeekly} €
                        </>
                      ) : (
                        <>{prices.classic.subOneWeekly} €</>
                      )}
                    </div>
                    <br />
                    <span className={styles.subprice}>
                      prix / séance / pers <br />
                      (6 mois minimum)
                    </span>
                  </Table.TextCell>
                  <Table.TextCell className={styles.bodyCell}>
                    <div className={styles.price}>
                      {prices.withPromo ? (
                        <>
                          <span className={styles.strokePrice}>
                            {prices.classic.subThreeWeekly} €
                          </span>
                          {prices.withPromo.subThreeWeekly} €
                        </>
                      ) : (
                        <>{prices.classic.subThreeWeekly} €</>
                      )}
                    </div>
                    <br />
                    <span className={styles.subprice}>
                      prix / séance / pers <br />
                      (6 mois minimum)
                    </span>
                  </Table.TextCell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        )}

        {forCompany && (
          <div className={classnames(styles.pricesTabWrapper, { [styles.animated]: animated })}>
            <Table className={styles.twoColsTable}>
              <Table.Head className={styles.subscriptionHeader}>
                <Table.TextHeaderCell>TARFIS ENTREPRISE</Table.TextHeaderCell>
              </Table.Head>
              <Table.Head className={styles.typeHeader}>
                <Table.TextHeaderCell>Tarif de base</Table.TextHeaderCell>
                <Table.TextHeaderCell>+ DE PARTICIPANTS</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                <Table.Row className={styles.bodyTab}>
                  <Table.TextCell className={styles.bodyCell}>
                    <div className={styles.price}>90 €</div>
                    <br />
                    <span className={styles.subprice}>
                      tarif minimal / séance
                      <br />
                      {"( jusqu'à 12 personnes )"}
                    </span>
                  </Table.TextCell>
                  <Table.TextCell className={classnames(styles.bodyCell, styles.secondCol)}>
                    <div className={styles.price}>10 €</div>
                    <br />
                    <span className={styles.subprice}>
                      tarif / pers supplémentaire
                      <br />
                      {'( à partir de 12 personnes )'}
                    </span>
                  </Table.TextCell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        )}

        <Text className={styles.conditions}>
          {withSap && !forCompany ? `(*) Prix après réduction d’impôts ` : ''}
        </Text>

        <Title
          className={styles.title2}
          size={3}
          part1="COMMENT"
          part2="RÉSERVER VOTRE SÉANCE ?"
          part2Color="green"
          isCentered
        />

        <Text className={styles.textBooking}>
          {`Trois options pour vous permettre de d'organiser votre premier rendez-vous et commencer
          vos entraînements.`}
        </Text>

        <IconBlocks
          items={contactMe}
          nbCols={3}
          nbColsTablet={3}
          nbColsMobile={1}
          iconPosition="left"
          marginSize="S"
        />

        <Text className={styles.titleCGV}>LES CONDITIONS GÉNÉRALES DE RÉSERVATION</Text>
        <Text className={styles.cgv}>
          Tout changement de date ou d’heure d’une séance doit être effectué par téléphone ou e-mail
          au moins 48h à l’avance. Le non respect de la règle ou du délai d’annulation a pour
          conséquence la facturation de la globalité de la séance. Tout retard ou absence du client
          entraînant l’attente de l’intervenant sera facturé. Dans le cas d’une résiliation émenant
          du client : celui-ci s’engage à prendre en charge 30 % du coût des prestations qui
          auraient dû être réalisées.
        </Text>

        <BlockSeparator blocks={redirectionBlocks} />
      </Section>

      <Mozaik pictures={mozaikPictures} />

      <Modal
        open={isModalOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        {modalContent.current && (
          <>
            <Title
              size={3}
              part1={modalContent.current.titlePart1}
              part2={modalContent.current.titlePart2}
              part2Color="green"
              isCentered
            />
            <Text className={styles.text}>{modalContent.current.text}</Text>
          </>
        )}
      </Modal>
    </>
  );
};
